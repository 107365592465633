import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractComponent, Config, Empresa, Franquia, PublicConfigService, PublicEmpresaService, PublicFranquiaService, PublicSenhaService, Senha } from "lib-smart-core";
import { Subject, takeUntil, timer } from 'rxjs';
import { sessionSemPainel } from '../../app.types';

@Component({
  selector: 'confirmacao-senha-component',
  templateUrl: './confirmacao-senha.component.html'
})
export class ConfirmacaoSenhaComponent extends AbstractComponent implements OnInit, OnDestroy {

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  senha: Senha;
  modelWhatsApp: any;

  contador: number;
  interval: any;

  constructor(
    private configService: PublicConfigService,
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    private senhaService: PublicSenhaService,
    injector: Injector
  ) {
    super(injector);
    this.contador = 40;
  }

  ngOnInit() {
    const codigo = super.getParam('codigo');
    const idSenha = super.getParam('idSenha');
    if (idSenha && codigo) {
      this.loadSenha(idSenha, codigo);
      this.loadFranquiaPorCodigo(codigo);
    }
    this.redirectBegin();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirectBegin() {
    timer(0, 1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.contador--;
        if (this.contador === 0) {
          this.done();
        }
      });
  }

  loadSenha(idSenha: string, codigo: string) {
    this.senhaService.getById(idSenha).subscribe(
      {
        next: (senha: Senha) => {
          this.senha = senha;
          this.modelWhatsApp.nome = senha.nome || '';
          this.modelWhatsApp.numero = senha.numero || '';
          if (codigo) {
            this.loadFranquiaPorCodigo(codigo);
          }
        },
        error: err => this.alertService.error(err.error.message)
      }
    );
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
        this.loadFranquia();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.loadConfig();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadConfig() {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config: Config) => {
        this.config = config;
      }, err => this.alertService.error(err.error.message)
    );
  }

  done() {
    const session = sessionStorage.getItem(sessionSemPainel);
    if (session) {
      this.router.navigate(['filas', this.franquia.codigo], { queryParams: { panel: false } });
    } else {
      this.router.navigate(['filas-qrcode', this.franquia.codigo]);
    }
  }

}
