import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { CadastroComponent } from './cadastro/cadastro.component';
import { FilaComponent } from './filas/fila.component';
import { FilasQRCodeComponent } from './filas/filas-qrcode.component';
import { LinksComponent } from './links/links.component';
import { ObrigadoComponent } from './obrigado/obrigado.component';
import { PainelComponent } from './painel/painel.component';
import { TabletQRCodeComponent } from './tablet/tablet-qrcode.component';
import { TabletComponent } from './tablet/tablet.component';
import { ConfirmacaoSenhaComponent } from './confirmacao-senha/confirmacao-senha.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LinksComponent,
    FilaComponent,
    PainelComponent,
    ObrigadoComponent,
    TabletComponent,
    CadastroComponent,
    FilasQRCodeComponent,
    TabletQRCodeComponent,
    ConfirmacaoSenhaComponent,
  ],
  exports: [
    LinksComponent,
    FilaComponent,
    PainelComponent,
    ObrigadoComponent,
    TabletComponent,
    CadastroComponent,
    FilasQRCodeComponent,
    TabletQRCodeComponent,
    ConfirmacaoSenhaComponent,
  ],
  providers: [
  ]
})
export class AppsModule { }
