import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Config, Empresa, Franquia, PublicConfigService, PublicEmpresaService, PublicFranquiaService } from "lib-smart-core";

@Component({
  selector: 'obrigado-component',
  templateUrl: './obrigado.component.html'
})
export class ObrigadoComponent extends AbstractComponent implements OnInit {

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;

  constructor(
    private configService: PublicConfigService,
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let codigo = super.getParam('codigo');
    if (codigo) {
      this.loadFranquiaPorCodigo(codigo);
    }
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
        this.loadFranquia();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        // console.log('loadFranquia');
        // console.log(franquia);
        this.franquia = franquia;
        this.loadConfig();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadConfig() {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config: Config) => {
        this.config = config;
      }, err => this.alertService.error(err.error.message)
    );
  }

}
