<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
          [imgStyles]="'max-height: 40px'"></logo-image>
      </a>
    </div>
    <div></div>
  </div>
</nav>
<div id="SSOTV" *ngIf="!franquia?.bloqueado">
  <div class="row">
    <div>
      <div class="smartline-tv">
        <div class="smartline-tv__header"
          [ngStyle]="{'color':empresa?.textColor,'background-color': empresa?.bgColor }">
          <h4 class="smartline-tv__heading">CHAMADA ATUAL</h4>
          <span class="smartline-tv__text" id="senha">{{model.senha}} </span>
          <h4 class="smartline-tv__heading">{{getNomeChamada(model.tipoChamada)}}</h4>
          <span class="smartline-tv__text" id="mesa"
            [ngClass]="(model?.mesa === 'ESTOQUE' || model?.mesa === 'CAIXA') ? 'mesa5vw' : 'smartline-tv__text'">{{model.mesa}}</span>
        </div>
        <div class="smartline-tv__content">
          <div class="smartline-tv__last">
            <h3 class="smartline-tv__subheading">Últimas chamadas</h3>
            <ul class="smartline-tv__list">
              <li *ngFor="let h of historico; let i=index" id="h-{{i}}"
                [ngClass]="{'hidden-mob-400': (i === 3) || (i === 4)}">
                <span class="text-center">{{h?.senha?.senha}}
                  {{h.fila?.tipoChamada !== '' ? ' → ' + getNomeChamada(h.fila?.tipoChamada) + ' ' + (!!h.mesa ? h.mesa?.nome : '' ) : ' '}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="smartline-tv__footer">
          <h3>SUA SENHA</h3>
          <h4>{{senha?.senha}}</h4>
          <p><strong>{{senha?.fila.nome | uppercase}}</strong></p>
        </div>
        <h5 class="text-center">{{franquia?.nome}}</h5>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="!franquia?.bloqueado">
  <ng-container *ngIf="empresa?.senderEnabled && !chamada.foiChamado">
    <div class="row text-center" *ngIf="showButtonNotifications">
      <!-- <p class="lead text-center mt-4" *ngIf="senha.permiteWhatsApp">
        <i class="fa fa-whatsapp"></i> WhatsApp  Habilitado
      </p> -->
      <button type="button" *ngIf="!permiteWhatsApp" class="btn btn-default mt20 btn-whatsapp"
        [disabled]="loadingHabilitarNotificacao" data-toggle="modal" data-target="#modalHabilitarWhatsApp">
        <i class="fa fa-whatsapp"></i> Acompanhar pelo WhatsApp
        <img *ngIf="loadingHabilitarNotificacao"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button>
      <p class="lead text-center" *ngIf="permiteWhatsApp">
        <i class="fa fa-whatsapp text-whatsapp"></i> Acompanhamento via WhatsApp Habilitado
      </p>
    </div>
    <div class="row text-center">
      <button type="button" class="btn btn-danger mt20" data-toggle="modal"
        data-target="#modalSairDaFila">
        <i class="fa fa-sign-out"></i> Sair da Fila
        <img *ngIf="loadingSairDaFila"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button>
    </div>
  </ng-container>

  <div class="redesocial-wrapper" *ngIf="empresa?.links && !franquia?.bloqueado">
    <div class="row redesocial">
      <div *ngFor="let item of empresa.links">
        <a class="redesocial__item" [ngStyle]="{'color':empresa?.textColor,'background-color': empresa?.bgColor }"
          *ngIf="item.active" href="{{item.url}}" target="_blank" title="{{item.url}}">
          <i class="fa {{item.icon}} fa-2x"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<div style="display: none">
  <a (click)="onBtnAtualizar()" #btnAtualizar class="btn btn-primary btn-sm ">ATUALIZA LISTA CHAMADA</a>
  <a data-toggle="modal" #btnChamada data-target="#modalChamadaLite" class="btn btn-primary btn-sm ">MODAL CHAMADA
    LITE</a>
  <a data-toggle="modal" #btnAvaliacao data-target="#modalAvaliacaoLite" class="btn btn-primary btn-sm ">MODAL AVALIACAO
    LITE</a>
</div>


<div class="modal" id="modalChamadaLite" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content animated bounceInRight modal-danger">
      <div class="modal-body">
        <button type="button" class="close" #btnChamadaDismiss data-dismiss="modal"><span
            aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
        <div class="icon-border">
          !
        </div>
        <h1>Atenção</h1>
        <h2>Sua senha está sendo chamada!</h2>
        <div class="smartline-tv__header smartline-tv__header--white">
          <h4 class="smartline-tv__heading">Senha</h4>
          <span class="smartline-tv__text" id="senha">{{model.senha}} </span>
          <h4 class="smartline-tv__heading">{{ getNomeChamada(model.tipoChamada) }}</h4>
          <span class="smartline-tv__text" id="mesa"
            [ngClass]="(model?.mesa === 'ESTOQUE' || model?.mesa === 'CAIXA') ? 'mesa5vw' : 'smartline-tv__text'">{{model.mesa}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modalAvaliacaoLite" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content animated bounceInRight modal-danger">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h2>Gostaria de avaliar sua experiência?</h2>
        <p class="mt-2">
          <button type="button" class="btn btn-white" data-dismiss="modal">Não</button>
          <button type="button" class="btn btn-white mr-10" data-dismiss="modal" (click)="goToAvaliacao()">Sim</button>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modalHabilitarWhatsApp" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content animated bounceInRight modal-danger">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <div class="icon-border">
          <i class="fa fa-whatsapp"></i>
        </div>
        <h1>Acompanhar pelo WhatsApp</h1>
        <h2>
          Ativando está função, você receberá atualizações da fila por WhatsApp.
        </h2>
        <br />
        <div class="form-group form-group-lg">
          <label>Nome</label>
          <input type="text" placeholder="" class="form-control" name="nome" id="nome" #nome="ngModel"
            [(ngModel)]="modelWhatsApp.nome">
        </div>
        <div class="form-group form-group-lg">
          <label>Número de WhatsApp</label>
          <input type="tel" placeholder="" class="form-control" name="numero" id="numero" #numero="ngModel"
            mask="(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
            [(ngModel)]="modelWhatsApp.numero">
        </div>
        <br />
        <p class="mt-">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-white mr-10" data-dismiss="modal"
            (click)="habilitarNotificacoes()">Continuar</button>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modalSairDaFila" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content animated bounceInRight modal-danger">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <div class="icon-border">
          <i class="fa fa-sign-out"></i>
        </div>
        <h1>Sair da Fila</h1>
        <h2>
          Tem certeza que deseja sair da fila?
        </h2>
        <br />
        <p class="mt-">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-white mr-10" data-dismiss="modal"
            (click)="sairDaFila()">Continuar</button>
        </p>
      </div>
    </div>
  </div>
</div>
