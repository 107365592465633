import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AlertModule, CONSTANTES, ENV, customHttpProvider } from 'lib-smart-core';
import { ToastrModule } from 'ngx-toastr';
import { Constantes } from '../environments/constantes';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppsModule } from './modules/apps/apps.module';
import { FranquiaComponent } from './modules/franquia/franquia.component';
import { LayoutModule } from './modules/layout/layout.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    FranquiaComponent,
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    LayoutModule,
    AppsModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AlertModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    customHttpProvider,
    { provide: ENV, useValue: environment },
    { provide: CONSTANTES, useValue: Constantes },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
