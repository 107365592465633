import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smartline-web-lite',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
