<div class="flex justify-content-center align-items-center height-full-vh">
  <div class="width-full-vw">
    <section class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="img-container">
            <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
              [imgClass]="'tablet-windows'"></logo-image>
          </div>
        </div>
      </div>
      <div class="row text-center" *ngIf="!franquia?.bloqueado">
        <a *ngIf="!production" href="{{urlFilas}}" target="_blank">{{urlFilas}}</a>
        <h3 class="smartline__heading4">Leia o QR Code para entrar na fila</h3>
        <div class="col-xs-12 col-md-8 col-md-offset-2" *ngIf="urlFilas">

          <div class="qr-code">
            <qrcode [qrdata]="urlFilas" [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
          </div>

          <h4 class="smartline__heading4">OU, SE PREFERIR, ENTRE DIGITANDO NOME E TELEFONE</h4>

          <button class="btn btn-primary btn-xlg btn-block smartline__button smartline__button--md"
            [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
            (click)="goToContinue()">Entrar</button>
        </div>
      </div>
      <div class="row text-center">
        <h5>{{franquia?.nome}}</h5>
      </div>
    </section>
  </div>
</div>