import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractComponent, Config, Empresa, Fila, Franquia, PublicConfigService, PublicEmpresaService, PublicFranquiaService } from "lib-smart-core";
import { mergeMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { sessionFilasQRCode } from '../../app.types';

@Component({
  selector: 'filas-qrcode-component',
  templateUrl: './filas-qrcode.component.html',
  styleUrls: ['./filas-qrcode.component.css'],
})
export class FilasQRCodeComponent extends AbstractComponent implements OnInit, OnDestroy {

  production: boolean = environment.production;

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[];
  loading: boolean = false;

  urlFilas: string = undefined;

  constructor(
    private configService: PublicConfigService,
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let codigo = super.getParam('codigo');
    if (codigo) {
      this.franquiaService.getFranquiaPorCodigo(codigo).pipe(
        mergeMap((franquia: Franquia) => {
          this.franquia = franquia;
          this.idFranquia = this.franquia._id;
          this.idEmpresa = this.franquia.empresa._id;
          return this.empresaService.getPublic(this.idEmpresa);
        }),
        mergeMap((empresa: Empresa) => {
          this.empresa = empresa;
          return this.configService.getConfigPorFranquia(this.idFranquia);
        }),
      ).subscribe((config: Config) => {
        this.config = config;
        const baseUrl = (environment.publicUrl)
        this.urlFilas = `${baseUrl}/filas/${codigo}`;
      });
    }
  }

  public goToContinue() {
    sessionStorage.setItem(sessionFilasQRCode, String(true));
    this.router.navigate(['/filas/', this.franquia?.codigo]);
  }

}
