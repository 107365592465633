<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
          [imgStyles]="'max-height: 40px'"></logo-image>
      </a>
    </div>
    <div></div>
  </div>
</nav>
<div>
  <section class="container-fluid" *ngIf="!franquia?.bloqueado">
    <div class="smartline-acompanhe smartline-acompanhe--widthless">
      <h3 class="smartline-acompanhe__title text-center">Peque sua senha</h3>
      <p class="smartline__text text-center">Abra o app de Leitor de QRcode em seu telefone e escaneie o código abaixo:
      </p>
    </div>
    <div class="sso-tablet">
      <div class="row">
        <div class="col-12 text-center">
          <h3>SmartFilas Lite: Acesso via celular</h3>
          <div class=" form-group">
            <!-- <qrcode [qrdata]="model.urlFilas" [width]="128" [errorCorrectionLevel]="'M'"></qrcode> -->
            <!-- <input type="text" class="form-control text-center input-lg" value="{{model.urlFilas}}" #userinput> -->
            <h4>{{model.urlFilas}}</h4>
            <button class="btn btn-default btn-lg" (click)="goTo(model.urlFilas)" value="Acessar">Acessar</button>
            <button class="btn btn-default btn-lg" (click)="copyCode(model.urlFilas)" value="Copiar">Copiar</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-12 text-center">
          <h3>SmartFilas Lite: Acesso Tablet modo de entrada QR Code ativado</h3>
          <div class=" form-group">
            <!-- <qrcode [qrdata]="model.urlFilas" [width]="128" [errorCorrectionLevel]="'M'"></qrcode> -->
            <!-- <input type="text" class="form-control text-center input-lg" value="{{model.urlFilas}}" #userinput> -->
            <h4>{{model.urlFilas}}</h4>
            <button class="btn btn-default btn-lg" (click)="goTo(model.urlFilasQRCode)" value="Acessar">Acessar</button>
            <button class="btn btn-default btn-lg" (click)="copyCode(model.urlFilasQRCode)" value="Copiar">Copiar</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <!-- <div class="row">
        <div class="col-12 text-center">
          <h3>Painel de Atendimento - Smartphones</h3>
          <div class=" form-group">
            <qrcode [qrdata]="model.urlPainel" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
            <h3>{{model.urlPainel}}</h3>
            <button class="btn btn-default btn-lg" (click)="goTo(model.urlPainel)" value="Acessar">Acessar</button>
            <button class="btn btn-default btn-lg" (click)="copyCode(model.urlPainel)" value="Copiar">Copiar</button>
          </div>
        </div>
      </div>
      <br />
      <br /> -->
      <div class="row">
        <div class="col-12 text-center">
          <h3>SmartFilas: Totem com modo de entrada QR Code desativado</h3>
          <div class=" form-group">
            <!-- <qrcode [qrdata]="model.urlTabletWin" [width]="128" ee[errorCorrectionLevel]="'M'"></qrcode> -->
            <!-- <input type="text" class="form-control text-center input-lg" value="{{model.urlTabletWin}}" #userinput3> -->
            <h4>{{model.urlTablet}}</h4>
            <button class="btn btn-default btn-lg" (click)="goTo(model.urlTablet)" value="Acessar">Acessar</button>
            <button class="btn btn-default btn-lg" (click)="copyCode(model.urlTablet)" value="Copiar">Copiar</button>
          </div>
          <br />
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-12 text-center">
          <h3>SmartFilas: Totem com modo de entrada QR Code ativado</h3>
          <div class=" form-group">
            <!-- <qrcode [qrdata]="model.urlTabletWin" [width]="128" ee[errorCorrectionLevel]="'M'"></qrcode> -->
            <!-- <input type="text" class="form-control text-center input-lg" value="{{model.urlTabletWin}}" #userinput3> -->
            <h4>{{model.urlTabletQRCode}}</h4>
            <button class="btn btn-default btn-lg" (click)="goTo(model.urlTabletQRCode)" value="Acessar">Acessar</button>
            <button class="btn btn-default btn-lg" (click)="copyCode(model.urlTabletQRCode)" value="Copiar">Copiar</button>
          </div>
          <br />
        </div>
      </div>
      <br />
      <br />
    </div>
  </section>
</div>