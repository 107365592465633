import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CadastroComponent } from './modules/apps/cadastro/cadastro.component';
import { FilaComponent } from './modules/apps/filas/fila.component';
import { FilasQRCodeComponent } from './modules/apps/filas/filas-qrcode.component';
import { LinksComponent } from './modules/apps/links/links.component';
import { ObrigadoComponent } from './modules/apps/obrigado/obrigado.component';
import { PainelComponent } from './modules/apps/painel/painel.component';
import { TabletQRCodeComponent } from './modules/apps/tablet/tablet-qrcode.component';
import { TabletComponent } from './modules/apps/tablet/tablet.component';
import { FranquiaComponent } from './modules/franquia/franquia.component';
import { ConfirmacaoSenhaComponent } from './modules/apps/confirmacao-senha/confirmacao-senha.component';

const appRoutes: Routes = [
  { path: '', component: FranquiaComponent },

  // links
  { path: 'links/:codigo', component: LinksComponent },

  // filas
  { path: 'filas/:codigo', component: FilaComponent },
  { path: 'filas-qrcode/:codigo', component: FilasQRCodeComponent },

  // painel
  { path: 'painel/:codigo', component: PainelComponent },
  { path: 'painel/:codigo/senha/:idSenha', component: PainelComponent },
  { path: 'painel/cadastro/:codigo/fila/:idFila/:preferencial', component: CadastroComponent },

  // obrigado
  { path: 'obrigado/:codigo', component: ObrigadoComponent },
  { path: 'confirmacao/:codigo/senha/:idSenha', component: ConfirmacaoSenhaComponent },

  // app-tablet
  { path: 'app/tablet/:codigo', component: TabletComponent },
  { path: 'tablet/:codigo', component: TabletComponent },
  { path: 'tablet-qrcode/:codigo', component: TabletQRCodeComponent },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
