import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, Config, Empresa, Fila, Franquia, PublicConfigService, PublicEmpresaService, PublicFilaService, PublicFranquiaService, PublicSenhaService, Senha } from "lib-smart-core";
import { mergeMap } from 'rxjs';
import { sessionFilasQRCode } from '../../app.types';

@Component({
  selector: 'fila-component',
  templateUrl: './fila.component.html',
  styleUrls: ['./fila.component.css'],
})
export class FilaComponent extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('btnCloseSelectNormalPreferencial') btnCloseSelectNormalPreferencial: ElementRef;

  senhaSelecionada: Senha;
  filaSelecionada: Fila;

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[];
  loading: boolean = false;

  constructor(
    private configService: PublicConfigService,
    private filaService: PublicFilaService,
    private franquiaService: PublicFranquiaService,
    private senhaService: PublicSenhaService,
    private empresaService: PublicEmpresaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let redirect = super.getQueryParam('redirect');
    let codigo = super.getParam('codigo');
    if (codigo) {
      this.franquiaService.getFranquiaPorCodigo(codigo).pipe(
        mergeMap((franquia: Franquia) => {
          this.franquia = franquia;
          this.idFranquia = this.franquia._id;
          this.idEmpresa = this.franquia.empresa._id;
          return this.configService.getConfigPorFranquia(this.idFranquia);
        }),
        mergeMap((config: Config) => {
          this.config = config;
          return this.empresaService.getPublic(this.idEmpresa);
        }),
        mergeMap((empresa: Empresa) => {
          this.empresa = empresa;
          return this.filaService.getFilasPorFranquia(this.idFranquia)
        }),
      ).subscribe((filas: Fila[]) => {
        this.filas = filas;
      });
    }
  }

  override ngOnDestroy(): void {
    this.btnCloseSelectNormalPreferencial.nativeElement.click();
  }

  gerarNovaSenha(fila: Fila, preferencial = false) {

    if (fila.dadosContato) {
      this.router.navigate(['painel/cadastro', this.franquia.codigo, 'fila', fila._id, preferencial]);
    } else {
      this.loading = true;
      this.senhaService.gerarNovaSenhaMetadata(fila._id, { app: 'LITE' }, preferencial).subscribe(
        (senha: Senha) => {
          this.loading = false;
          this.senhaSelecionada = senha;
          this.filaSelecionada = fila;

          const session = sessionStorage.getItem(sessionFilasQRCode);
          if (session) {
            this.router.navigate(['filas-qrcode', this.franquia.codigo]);
          } else {
            this.router.navigate(['painel', this.franquia.codigo, 'senha', senha._id]);
          }

        }, err => {
          this.loading = false;
          this.alertService.error(err.error.message)
        }
      );
    }
  }

  selecionar(fila: Fila) {
    this.filaSelecionada = fila;
  }

}
