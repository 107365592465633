import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Config, Empresa, Franquia, PublicConfigService, PublicEmpresaService, PublicFranquiaService, PublicSenhaService, Senha } from "lib-smart-core";

@Component({
  selector: 'confirmacao-senha-component',
  templateUrl: './confirmacao-senha.component.html'
})
export class ConfirmacaoSenhaComponent extends AbstractComponent implements OnInit {

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  senha: Senha;
  modelWhatsApp: any;

  contador: number;
  interval: any;

  constructor(
    private configService: PublicConfigService,
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    private senhaService: PublicSenhaService,
    injector: Injector
  ) {
    super(injector);
    this.contador = 40;
  }

  ngOnInit() {
    const codigo = super.getParam('codigo');
    const idSenha = super.getParam('idSenha');
    if (idSenha && codigo) {
      this.loadSenha(idSenha, codigo);
      this.loadFranquiaPorCodigo(codigo);
    }
    this.redirectBegin();
  }

  override ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  redirectBegin() {
    this.interval = setInterval(() => {
      this.contador--;
      if (this.contador === 0) {
        clearInterval(this.interval);
        this.router.navigate(['filas-qrcode', this.franquia.codigo]);
      }
    }, 1000);
  }

  loadSenha(idSenha: string, codigo: string) {
    this.senhaService.getById(idSenha).subscribe(
      {
        next: (senha: Senha) => {
          this.senha = senha;
          this.modelWhatsApp.nome = senha.nome || '';
          this.modelWhatsApp.numero = senha.numero || '';
          if (codigo) {
            this.loadFranquiaPorCodigo(codigo);
          }
        },
        error: err => this.alertService.error(err.error.message)
      }
    );
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
        this.loadFranquia();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        // console.log('loadFranquia');
        // console.log(franquia);
        this.franquia = franquia;
        this.loadConfig();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadConfig() {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config: Config) => {
        this.config = config;
      }, err => this.alertService.error(err.error.message)
    );
  }

  done() {
    this.router.navigate(['filas-qrcode', this.franquia.codigo]);
  }

}
