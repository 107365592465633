import { AfterContentInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, Aviso, Chamada, Config, Empresa, Franquia, PublicConfigService, PublicEmpresaService, PublicFranquiaService, PublicSenhaService, Sender, Senha, SocketService } from "lib-smart-core";
import { timer } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'painel-component',
  styleUrls: ['./painel.component.css'],
  templateUrl: './painel.component.html'
})
export class PainelComponent extends AbstractComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('btnAtualizar') btnAtualizar: ElementRef;
  @ViewChild('btnAvisos') btnAvisos: ElementRef;
  @ViewChild('btnAvaliacao') btnAvaliacao: ElementRef;
  @ViewChild('btnChamada') btnChamada: ElementRef;
  @ViewChild('btnChamadaDismiss') btnChamadaDismiss: ElementRef;

  @ViewChild('btnUpdateToken') btnUpdateToken: ElementRef;

  idGrupo: string = null;
  idEmpresa: string = null;
  idFranquia: string = null;

  avisos: Aviso[];
  historico: any;

  tmpID: string;
  tmpSENHA: string;
  tmpMESA: string;
  tmpCHAMADA: string;

  // tela
  model: any;

  // tela form whatsapp
  modelWhatsApp: any;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  senha: Senha;
  chamada: Chamada;

  permiteWhatsApp: boolean = false;
  loadingHabilitarNotificacao: boolean = false;
  showButtonNotifications = false;
  loadingSairDaFila: boolean = false;

  constructor(
    private socketService: SocketService,
    private franquiaService: PublicFranquiaService,
    private senhaService: PublicSenhaService,
    private empresaService: PublicEmpresaService,
    private configService: PublicConfigService,
    injector: Injector
  ) {
    super(injector);
  }

  ngAfterContentInit(): void {
  }

  ngOnInit() {
    this.model = { senha: 'N001', mesa: '', tipoChamada: '' };
    this.modelWhatsApp = { senha: '', numero: '' };
    const codigo = super.getParam('codigo');
    const idSenha = super.getParam('idSenha');
    if (idSenha && codigo) {
      this.loadSenha(idSenha, codigo);
    } else if (codigo) {
      this.loadFranquiaPorCodigo(codigo);
    }
    this.setupShowButtonNotification()
  }

  override ngOnDestroy(): void {
    this.socketService.disconnect();
  }

  setupShowButtonNotification() {
    this.showButtonNotifications = true;
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.loadFranquia();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadConfig() {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config: Config) => {
        this.config = config;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadSenha(idSenha: string, codigo: string) {
    this.senhaService.getById(idSenha).subscribe(
      (senha: Senha) => {
        this.senha = senha;
        this.modelWhatsApp.nome = senha.nome || '';
        this.modelWhatsApp.numero = senha.numero || '';
        this.permiteWhatsApp = senha.permiteWhatsApp || false;
        this.loadChamada();
        if (codigo) {
          this.loadFranquiaPorCodigo(codigo);
        }
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadChamada() {
    this.senhaService.getChamadaPorIdSenha(this.senha._id).subscribe(
      (chamada: Chamada) => {
        this.chamada = chamada;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
        this.loadHistorico();
        this.loadConfig();
        this.configSocket();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadHistorico() {
    this.senhaService.historicoChamadasPublic(this.idFranquia).subscribe(
      (historico: any[]) => {
        this.historico = historico;
        if (historico && historico.length > 0) {
          this.model._id = historico[0].senha._id;
          this.model.senha = historico[0].senha.senha;
          const tipoChamada = historico[0].senha.fila.tipoChamada;
          if (tipoChamada !== '') {
            this.model.tipoChamada = historico[0].senha.fila.tipoChamada;
            this.model.mesa = historico[0]?.mesa?.nome;
          }
        }
      }, err => this.alertService.error(err.error.message)
    );
  }

  onBtnAtualizar() {
    this.model._id = this.tmpID;
    this.model.senha = this.tmpSENHA;
    if (this.tmpCHAMADA !== '') {
      this.model.tipoChamada = this.tmpCHAMADA;
      this.model.mesa = this.tmpMESA;
    } else {
      this.model.tipoChamada = '';
      this.model.mesa = '';
    }
    this.checkChamadaSenhaSelecionada();
    this.historico = this.event_senha_data.historico;
    if (this.historico && this.historico.length > 0) {
      this.model._id = this.historico[0].senha._id;
      this.model.senha = this.historico[0].senha.senha;
      const tipoChamada = this.historico[0].senha.fila.tipoChamada;
      if (tipoChamada !== '') {
        this.model.tipoChamada = this.historico[0].senha.fila.tipoChamada;
        this.model.mesa = this.historico[0].mesa.nome;
      }
    }
    this.playAudio();
  }

  onBtnAtualizar2() {
    this.model._id = this.tmpID;
    this.model.senha = this.tmpSENHA;
    if (this.tmpCHAMADA !== '') {
      this.model.tipoChamada = this.tmpCHAMADA;
      this.model.mesa = this.tmpMESA;
    } else {
      this.model.tipoChamada = '';
      this.model.mesa = '';
    }
    this.checkChamadaSenhaSelecionada();
    // this.loadChamada();
    this.loadHistorico();
    this.playAudio();
  }

  playAudio() {
    if (this.chamada.foiChamado === false) {
      this.loadChamada();
    }
  }

  checkChamadaSenhaSelecionada() {
    if (this.senha) {
      if (this.senha._id === this.model._id) {
        this.btnChamada.nativeElement.click();
        let duracao: number = 1000 * 60 * 5;

        if (!!this.config?.habilitarSmartAvalia) {
          if (!!this.config?.duracaoAvalia) {
            duracao = +this.config.duracaoAvalia;
          }
          if (duracao > 0) {
            timer(duracao).subscribe(() => {
              this.btnChamadaDismiss.nativeElement.click();
              this.btnAvaliacao.nativeElement.click();
            });
          }
        }
      }
    }
  }

  event_senha_data: any;

  configSocket(): void {
    this.socketService.connect();

    const sname = 'event_senha_' + this.idFranquia;
    this.socketService.listen(sname).subscribe(
      (data: any) => {
        this.event_senha_data = data;
        this.tmpID = data.id;
        this.tmpSENHA = data.senha;
        this.tmpMESA = data.mesa;
        this.tmpCHAMADA = data.fila.tipoChamada;
        this.btnAtualizar.nativeElement.click();
      }
    )
  }

  goToAvaliacao() {
    // this.router.navigate(['avaliacao', this.franquia.codigo])
    this.config
    window.location.href = (environment.smartAvaliaUrl) + '/feedback/' + this.franquia.codigo + '/principal';
  }

  private messages: string[] = [
    'Quero receber notificações sobre a fila por WhatsApp.',
    'Desejo ser notificado(a) sobre a fila através do WhatsApp.',
    'Quero acompanhar a fila por WhatsApp.',
    'Autorizo o envio de notificações sobre a fila pelo WhatsApp.',
    'Quero ser informado(a) sobre a fila pelo WhatsApp.',
    'Desejo ser notificado(a) sobre a fila pelo WhatsApp.',
    'Quero acompanhar a fila pelo WhatsApp.',
    'Solicito ser informado(a) sobre a fila pelo WhatsApp.',
    'Gostaria de receber atualizações sobre a fila pelo WhatsApp.',
  ]

  habilitarNotificacoes() {
    let timer: boolean = true;
    const sender: Sender = this.empresa.sender;
    if (!!sender && sender.type === 'WHATSAPP' && sender.impl === 'MESSAGEBIRD') {
      timer = false; // so envia mensagem antecipada para whatsapp nao oficial
    }

    const sendRandomMessage = () => {
      const randomMessageIndex = Math.floor(Math.random() * (this.messages.length - 1));
      const phone = sender.metadata['phone'];
      const text = this.messages[randomMessageIndex];
      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${text}`, '_blank');
    }

    this.loadingHabilitarNotificacao = true;
    this.senhaService.habilitarWhatsApp(this.senha._id, this.modelWhatsApp.nome, this.modelWhatsApp.numero, timer).subscribe({
      next: () => {
        this.permiteWhatsApp = true;
        this.loadingHabilitarNotificacao = false;
        if (timer) {
          sendRandomMessage();
        }
      },
      error: (error) => console.error(error)
    });
  }

  sairDaFila() {
    this.loadingSairDaFila = true;
    this.senhaService.sairDaFila(this.senha._id).subscribe({
      next: () => {
        this.loadingSairDaFila = false;
        this.router.navigate(['/filas', this.franquia.codigo]);
      },
      error: (error) => console.error(error)
    });
  }

}
