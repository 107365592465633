<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
          [imgStyles]="'max-height: 40px'"></logo-image>
      </a>
    </div>
    <div></div>
  </div>
</nav>
<div *ngIf="!franquia?.bloqueado">
  <section class="container-fluid">
    <div class="sso-tablet flex justify-content-between flex-direction-column height-90-vh">

      <div class="widget style1 row m-b-lg m-t-lg">
        <div class="col-xs-12 col-sm-12">
          <div class="logo text-center">
            <!-- <img src="{{empresa?.image}}" class="img-responsive smartline__cliente-logo" alt=""> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-4 col-sm-offset-4 text-center">
          <h1 class="smartline-acompanhe__title">OBRIGADO!</h1>
          <h3 class="smartline__text">Volte sempre!</h3>
        </div>
      </div>
      <div class="row">
        <div class="smartline-acompanhe__footer mt-5"
          style="display: flex; flex-direction: column; justify-content: center;">
          <img src="/assets/img/smartline-hori.svg" class="img-responsive" style="height: 20px;" alt="Smartline" />
          <p class="smartline-footer-url">www.smartline.com.br</p>
        </div>
      </div>
    </div>
  </section>
</div>