import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, Config, Empresa, Fila, Franquia, PublicConfigService, PublicEmpresaService, PublicFilaService, PublicFranquiaService, PublicSenhaService, Senha } from "lib-smart-core";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { mergeMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { sessionFilasQRCode } from '../../app.types';

@Component({
  selector: 'tablet',
  templateUrl: './tablet.component.html',
  styleUrls: ['./tablet.component.css'],
})
export class TabletComponent extends AbstractComponent implements OnInit {

  @ViewChild('btnCloseModalPreferencia') btnCloseModalPreferencia: ElementRef;

  senhaSelecionada: Senha;
  filaSelecionada: Fila;

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[];

  showOverlay: boolean;
  loading: boolean = false;

  constructor(
    private franquiaService: PublicFranquiaService,
    private configService: PublicConfigService,
    private filaService: PublicFilaService,
    private senhaService: PublicSenhaService,
    private empresaService: PublicEmpresaService,
    injector: Injector
  ) {
    super(injector);
    this.showOverlay = false;
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    let codigo = super.getParam('codigo');
    if (codigo) {
      this.franquiaService.getFranquiaPorCodigo(codigo).pipe(
        mergeMap((franquia: Franquia) => {
          this.franquia = franquia;
          this.idFranquia = this.franquia._id;
          this.idEmpresa = this.franquia.empresa._id;
          return this.configService.getConfigPorFranquia(this.idFranquia);
        }),
        mergeMap((config: Config) => {
          this.config = config;
          return this.empresaService.getPublic(this.idEmpresa);
        }),
        mergeMap((empresa: Empresa) => {
          this.empresa = empresa;
          return this.filaService.getFilasPorFranquia(this.idFranquia)
        }),
      ).subscribe((filas: Fila[]) => {
        this.filas = filas;
      });

    }
  }

  gerarNovaSenha(fila: Fila, preferencial = false) {
    this.showOverlay = true;
    this.loading = true;
    this.senhaService.gerarNovaSenhaMetadata(fila._id, { app: 'LITE' }, preferencial).subscribe(
      (senha: Senha) => {
        this.senhaSelecionada = senha;
        this.filaSelecionada = fila;

        setTimeout(() => {
          this.showOverlay = false;
          this.loading = false;
          this.btnCloseModalPreferencia.nativeElement.click();

          const session = sessionStorage.getItem(sessionFilasQRCode);
          if (session) {
            this.router.navigate(['tablet-qrcode', this.franquia.codigo]);
          }
        }, 3500);

        // this.buildPDF(senha, fila);

        let baseUrl: string = 'http://localhost:9000';
        let sameOriginEnable = super.getQueryParam('sameOriginEnable');
        if (sameOriginEnable === 'true') {
          baseUrl = `http://printer.smartline.com.br:9000`;
        }

        const url: string = `${baseUrl}/SmartLineFileServlet?senha=${senha.senha}&nomeFila=${fila.nome}&idSenha=${senha._id}&codigoFranquia=${this.franquia.codigo}`;
        const xhr = new XMLHttpRequest();
        const method = "GET";
        xhr.open(method, url, true);
        xhr.send();

      }, err => this.alertService.error(err.error.message)
    );
  }

  private async buildPDF(senha: Senha, fila: Fila) {
    const url: string = `${environment.publicUrl}/painel/${this.franquia.codigo}/senha/${senha._id}/`;
    const docDefinition = {
      pageSize: 'A7',
      pageOrientation: 'portrait',
      pageMargins: [0, 0, 0, 0],
      content: [
        { text: senha.senha, style: 'header', },
        { text: fila.nome.toUpperCase(), style: 'subheader' },
        { qr: url, style: 'qrcode', fit: 100 },
        { text: 'Leia o QRCode', style: 'small' },
        { text: '11/09/2023 23:32:58', style: 'xsmall' },
      ],
      styles: {
        header: {
          fontSize: 40,
          bold: true,
          alignment: 'center'
        },
        subheader: {
          fontSize: 20,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 15],
        },
        quote: {
          italics: true
        },
        qrcode: {
          margin: [0, 0, 0, 15],
          alignment: 'center'
        },
        small: {
          fontSize: 12,
          alignment: 'center',
          margin: [0, 0, 0, 10],
        },
        xsmall: {
          fontSize: 10,
          alignment: 'center'
        }
      }
    };

    // const win = window.open('', "tempWinForPdf");
    // pdfMake.createPdf(docDefinition).print({}, win);
    // win.close();
    pdfMake.createPdf(docDefinition).open();

    // const win = window.open('', "tempWinForPdf");
    // pdfMake.createPdf(docDefinition).print({}, win);


    // await new Promise((resolve) => setTimeout(resolve, 3500));
    // win.close();
  }

  selecionar(fila: Fila) {
    this.filaSelecionada = fila;
  }

}
